import { City } from '../dataTypes';

export const chicago: City = {
  dateAdded: '02/27/2021',
  name: 'Chicago',
  radio: [
    {
      url: 'https://stream.wbez.org/wbez128.mp3',
      name: 'WBEZ 91.5 FM',
      description: 'Chicago Public Radio',
    },
    {
      url: 'https://17473.live.streamtheworld.com/WBBMAMAAC_SC',
      name: 'WBBM Newsradio',
      description: 'News, Sports, Weather, Traffic',
    },
    {
      url: 'https://18843.live.streamtheworld.com/WXRTFMAAC_SC',
      name: '93XRT',
      description: 'Alternative Rock',
    },
    {
      url: 'https://14923.live.streamtheworld.com/WDRVFMAAC.aac',
      name: 'The Drive 97.1 FM',
      description: 'Chicago Classic Rock',
    },
  ],
  videos: [
    {
      url: 'https://www.youtube.com/watch?v=5alwy_vLWUM',
      time: 'day',
      method: 'walk',
      timeStart: 303,
    },
    {
      url: 'https://www.youtube.com/watch?v=5alwy_vLWUM',
      time: 'day',
      method: 'walk',
      timeStart: 2548,
    },
    {
      url: 'https://www.youtube.com/watch?v=5alwy_vLWUM',
      time: 'day',
      method: 'walk',
      timeStart: 3600,
    },
    {
      url: 'https://www.youtube.com/watch?v=vnias1yIyOQ',
      time: 'night',
      method: 'walk',
      timeStart: 341,
    },
    {
      url: 'https://www.youtube.com/watch?v=vnias1yIyOQ',
      time: 'day',
      method: 'walk',
      timeStart: 3600,
    },
    {
      url: 'https://www.youtube.com/watch?v=vnias1yIyOQ',
      time: 'day',
      method: 'walk',
      timeStart: 881,
    },
    {
      url: 'https://www.youtube.com/watch?v=OKrcvTSWGDM',
      time: 'day',
      method: 'walk',
      timeStart: 240,
    },
    {
      url: 'https://www.youtube.com/watch?v=rNoh3kwuKns',
      time: 'day',
      method: 'walk',
      timeStart: 96,
    },
    {
      url: 'https://www.youtube.com/watch?v=sb-EvlwB5g0',
      time: 'day',
      method: 'walk',
      timeStart: 145,
    },
    {
      url: 'https://www.youtube.com/watch?v=XrvkQ_uT3ow',
      time: 'day',
      method: 'walk',
      timeStart: 390,
    },
    {
      url: 'https://www.youtube.com/watch?v=uGVbb-cM_no',
      time: 'day',
      method: 'walk',
      timeStart: 16,
    },
    {
      url: 'https://www.youtube.com/watch?v=zoxlMXxEbg8',
      time: 'day',
      method: 'walk',
      timeStart: 83,
    },
    {
      url: 'https://www.youtube.com/watch?v=JEVpdAl5_zs',
      time: 'day',
      method: 'walk',
      timeStart: 1,
    },
    {
      url: 'https://www.youtube.com/watch?v=LLCcs6RP4nE',
      time: 'day',
      method: 'walk',
      timeStart: 75,
    },
    {
      url: 'https://www.youtube.com/watch?v=MNxm9Zsv9MA',
      time: 'day',
      method: 'walk',
      timeStart: 30,
    },
    {
      url: 'https://www.youtube.com/watch?v=Gm3cVlWTO8c',
      time: 'day',
      method: 'walk',
      timeStart: 50,
    },
    {
      url: 'https://www.youtube.com/watch?v=8rOpUN6gvtE',
      time: 'night',
      method: 'walk',
      timeStart: 175,
    },
    {
      url: 'https://www.youtube.com/watch?v=ZdSAVshf0c0',
      time: 'night',
      method: 'walk',
      timeStart: 26,
    },
    {
      url: 'https://www.youtube.com/watch?v=HprjiBIsr2E',
      time: 'night',
      method: 'walk',
      timeStart: 105,
    },
    {
      url: 'https://www.youtube.com/watch?v=YqKJhOJ3vcA',
      time: 'night',
      method: 'walk',
      timeStart: 240,
    },
    {
      url: 'https://www.youtube.com/watch?v=KKKWylanHro',
      time: 'night',
      method: 'walk',
      timeStart: 2729,
    },
    {
      url: 'https://www.youtube.com/watch?v=3ma3yXXc3V8',
      time: 'day',
      method: 'car',
      timeStart: 177,
    },
    {
      url: 'https://www.youtube.com/watch?v=hvFCn1KR_5I',
      time: 'day',
      method: 'car',
      timeStart: 226,
    },
    {
      url: 'https://www.youtube.com/watch?v=X7Mv6yugq1c',
      time: 'night',
      method: 'car',
      timeStart: 227,
    },
    {
      url: 'https://www.youtube.com/watch?v=hRenG276Afs',
      time: 'day',
      method: 'car',
      timeStart: 115,
    },
    {
      url: 'https://www.youtube.com/watch?v=kOMWAnxKq58',
      time: 'day',
      method: 'car',
      timeStart: 3,
    },
    {
      url: 'https://www.youtube.com/watch?v=x42Wxn1btTY',
      time: 'day',
      method: 'car',
      timeStart: 26,
    },
    {
      url: 'https://www.youtube.com/watch?v=MXzH3LzNM4Y',
      time: 'day',
      method: 'car',
      timeStart: 36,
    },
    {
      url: 'https://www.youtube.com/watch?v=5F3Hnkhw0fw',
      time: 'day',
      method: 'car',
      timeStart: 34,
    },
    {
      url: 'https://www.youtube.com/watch?v=3ma3yXXc3V8',
      time: 'night',
      method: 'car',
      timeStart: 2640,
    },
    {
      url: 'https://www.youtube.com/watch?v=fJzhNPKtR0Q',
      time: 'day',
      method: 'train',
      timeStart: 120,
    },
    {
      url: 'https://www.youtube.com/watch?v=fJzhNPKtR0Q',
      time: 'day',
      method: 'train',
      timeStart: 2040,
    },
    {
      url: 'https://www.youtube.com/watch?v=UQ7oTzKTJpY',
      time: 'night',
      method: 'train',
      timeStart: 1158,
    },
    {
      url: 'https://www.youtube.com/watch?v=UQ7oTzKTJpY',
      time: 'day',
      method: 'train',
      timeStart: 2100,
    },
    {
      url: 'https://www.youtube.com/watch?v=n6xJFpPY_7s',
      time: 'day',
      method: 'train',
      timeStart: 58,
    },
  ],
};
