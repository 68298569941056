import { City } from '../dataTypes';

export const istanbul: City = {
  name: 'Istanbul',
  radio: [
    {
      url: 'https://17733.live.streamtheworld.com/SUPER_FM.mp3',
      name: 'Süper FM',
      description: 'Turkish Hits',
    },
    {
      url: 'https://17773.live.streamtheworld.com/JOY_TURK.mp3',
      name: 'Joy Turk',
      description: 'Turkish Easy Listening, Variety',
    },
    {
      url: 'https://17733.live.streamtheworld.com/JOYTURK_ROCK.mp3',
      name: 'JoyTurk Rock',
      description: 'Turkish Rock',
    },
    {
      url: 'https://17773.live.streamtheworld.com/JOY_TURK.mp3',
      name: 'Süper FM',
      description: 'Turkish Classics',
    },
    {
      url: 'https://17773.live.streamtheworld.com/METRO_FM.mp3',
      name: 'Metro FM Istanbul',
      description: 'Pop, Hits',
    },
  ],
  videos: [
    {
      url: 'https://www.youtube.com/watch?v=SNkdN97hXzw',
      time: 'day',
      method: 'walk',
      timeStart: 480,
    },
    {
      url: 'https://www.youtube.com/watch?v=C796Mh7kMM8',
      time: 'day',
      method: 'walk',
      timeStart: 8,
    },
    {
      url: 'https://www.youtube.com/watch?v=jcnu9Q5Dm2A',
      time: 'day',
      method: 'walk',
      timeStart: 42,
    },
    {
      url: 'https://www.youtube.com/watch?v=IQH9krST07Y',
      time: 'day',
      method: 'walk',
      timeStart: 2,
    },
    {
      url: 'https://www.youtube.com/watch?v=mA9lYWyXMYU',
      time: 'day',
      method: 'walk',
      timeStart: 151,
    },
    {
      url: 'https://www.youtube.com/watch?v=PMIg8ex54IE',
      time: 'day',
      method: 'walk',
      timeStart: 33,
    },
    {
      url: 'https://www.youtube.com/watch?v=QOG6eR73tHQ',
      time: 'night',
      method: 'walk',
      timeStart: 34,
    },
    {
      url: 'https://www.youtube.com/watch?v=GhUbO_3PJAY',
      time: 'day',
      method: 'walk',
      timeStart: 1,
    },
    {
      url: 'https://www.youtube.com/watch?v=YjmTCPH2JIg',
      time: 'day',
      method: 'walk',
      timeStart: 11,
    },
    {
      url: 'https://www.youtube.com/watch?v=hdPTRcsFN5w',
      time: 'day',
      method: 'walk',
      timeStart: 47,
    },
    {
      url: 'https://www.youtube.com/watch?v=D8uJGePKAPk',
      time: 'day',
      method: 'walk',
      timeStart: 22,
    },
    {
      url: 'https://www.youtube.com/watch?v=7UA46OMPMTE',
      time: 'day',
      method: 'walk',
      timeStart: 59,
    },
    {
      url: 'https://www.youtube.com/watch?v=etaZ3S-KpKw',
      time: 'day',
      method: 'walk',
      timeStart: 34,
    },
    {
      url: 'https://www.youtube.com/watch?v=g_xWYt_sy58',
      time: 'day',
      method: 'walk',
      timeStart: 23,
    },
    {
      url: 'https://www.youtube.com/watch?v=2BnFnZwsVP0',
      time: 'day',
      method: 'walk',
      timeStart: 23,
    },
    {
      url: 'https://www.youtube.com/watch?v=FrEb-z5xmXE',
      time: 'night',
      method: 'walk',
      timeStart: 20,
    },
    {
      url: 'https://www.youtube.com/watch?v=V1gS841WPTg',
      time: 'day',
      method: 'walk',
      timeStart: 33,
    },
    {
      url: 'https://www.youtube.com/watch?v=YJ5pffYHVdo',
      time: 'day',
      method: 'walk',
      timeStart: 11,
    },
    {
      url: 'https://www.youtube.com/watch?v=M9OFNCUbNn0',
      time: 'day',
      method: 'walk',
      timeStart: 23,
    },
    {
      url: 'https://www.youtube.com/watch?v=90y0z5C9zVY',
      time: 'night',
      method: 'walk',
      timeStart: 33,
    },
    {
      url: 'https://www.youtube.com/watch?v=QOG6eR73tHQ',
      time: 'night',
      method: 'walk',
      timeStart: 44,
    },
    {
      url: 'https://www.youtube.com/watch?v=jrBYY4Z0Zww',
      time: 'night',
      method: 'walk',
      timeStart: 55,
    },
    {
      url: 'https://www.youtube.com/watch?v=UuOjHSz8V38',
      time: 'night',
      method: 'walk',
      timeStart: 25,
    },
    {
      url: 'https://www.youtube.com/watch?v=bNEGEb_YtmU',
      time: 'night',
      method: 'car',
      timeStart: 29,
    },
    {
      url: 'https://www.youtube.com/watch?v=cjspF43eNLg',
      time: 'night',
      method: 'car',
      timeStart: 56,
    },
    {
      url: 'https://www.youtube.com/watch?v=wWK8vFi1Sqs',
      time: 'day',
      method: 'car',
      timeStart: 1,
    },
    {
      url: 'https://www.youtube.com/watch?v=wWK8vFi1Sqs',
      time: 'night',
      method: 'car',
      timeStart: 999,
    },
    {
      url: 'https://www.youtube.com/watch?v=04OkpNrdbfc',
      time: 'night',
      method: 'car',
      timeStart: 33,
    },
    {
      url: 'https://www.youtube.com/watch?v=RKIPWCWldzE',
      time: 'day',
      method: 'car',
      timeStart: 43,
    },
    {
      url: 'https://www.youtube.com/watch?v=wMjIp-QSByU',
      time: 'day',
      method: 'car',
      timeStart: 8,
    },
    {
      url: 'https://www.youtube.com/watch?v=U41-fswE8Zo',
      time: 'day',
      method: 'car',
      timeStart: 33,
    },
    {
      url: 'https://www.youtube.com/watch?v=jPnWbCy6k3M',
      time: 'day',
      method: 'car',
      timeStart: 16,
    },
    {
      url: 'https://www.youtube.com/watch?v=tUVhJ-QeAaA',
      time: 'day',
      method: 'car',
      timeStart: 12,
    },
    {
      url: 'https://www.youtube.com/watch?v=z4PTOt2vQ44',
      time: 'day',
      method: 'train',
      timeStart: 327,
    },
    {
      url: 'https://www.youtube.com/watch?v=MPAgQcB55sU',
      time: 'day',
      method: 'train',
      timeStart: 466,
    },
    {
      url: 'https://www.youtube.com/watch?v=bDh9zhdwflc',
      time: 'night',
      method: 'train',
      timeStart: 489,
    },
    {
      url: 'https://www.youtube.com/watch?v=Ob5pK34DPEs',
      time: 'night',
      method: 'train',
      timeStart: 724,
    },
    {
      url: 'https://www.youtube.com/watch?v=Ob5pK34DPEs',
      time: 'day',
      method: 'train',
      timeStart: 724,
    },
    {
      url: 'https://www.youtube.com/watch?v=xY3czWw5rC4',
      time: 'day',
      method: 'train',
      timeStart: 416,
    },
    {
      url: 'https://www.youtube.com/watch?v=xY3czWw5rC4',
      time: 'day',
      method: 'train',
      timeStart: 416,
    },
  ],
};
