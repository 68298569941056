export const times = ['day', 'night'];
export const methods = ['car', 'walk', 'train'];
export const requiredMethods = ['car', 'walk'];
// generated from: https://coolors.co/f7c59f-2a324b-767b91-c7ccdb-e1e5ee
export const colors = {
  primary: 'rgba(247, 197, 159, 1)',
  primary01: 'rgba(247, 197, 159, 0.1)',
  primary02: 'rgba(247, 197, 159, 0.2)',
  primary03: 'rgba(247, 197, 159, 0.3)',
  primary04: 'rgba(247, 197, 159, 0.4)',
  primary05: 'rgba(247, 197, 159, 0.5)',
  primary06: 'rgba(247, 197, 159, 0.6)',
  primary07: 'rgba(247, 197, 159, 0.7)',
  primary08: 'rgba(247, 197, 159, 0.8)',
  primary09: 'rgba(247, 197, 159, 0.9)',
  darker: 'rgba(42, 50, 75, 1)',
  darker01: 'rgba(42, 50, 75, 0.1)',
  darker02: 'rgba(42, 50, 75, 0.2)',
  darker03: 'rgba(42, 50, 75, 0.3)',
  darker04: 'rgba(42, 50, 75, 0.4)',
  darker05: 'rgba(42, 50, 75, 0.5)',
  darker06: 'rgba(42, 50, 75, 0.6)',
  darker07: 'rgba(42, 50, 75, 0.7)',
  darker08: 'rgba(42, 50, 75, 0.8)',
  darker09: 'rgba(42, 50, 75, 0.9)',
  dark: 'rgba(118, 123, 145, 1)',
  dark01: 'rgba(118, 123, 145, 0.1)',
  dark02: 'rgba(118, 123, 145, 0.2)',
  dark03: 'rgba(118, 123, 145, 0.3)',
  dark04: 'rgba(118, 123, 145, 0.4)',
  dark05: 'rgba(118, 123, 145, 0.5)',
  dark06: 'rgba(118, 123, 145, 0.6)',
  dark07: 'rgba(118, 123, 145, 0.7)',
  dark08: 'rgba(118, 123, 145, 0.8)',
  dark09: 'rgba(118, 123, 145, 0.9)',
  light: 'rgba(199, 204, 219, 1)',
  light01: 'rgba(199, 204, 219, 0.1)',
  light02: 'rgba(199, 204, 219, 0.2)',
  light03: 'rgba(199, 204, 219, 0.3)',
  light04: 'rgba(199, 204, 219, 0.4)',
  light05: 'rgba(199, 204, 219, 0.5)',
  light06: 'rgba(199, 204, 219, 0.6)',
  light07: 'rgba(199, 204, 219, 0.7)',
  light08: 'rgba(199, 204, 219, 0.8)',
  light09: 'rgba(199, 204, 219, 0.9)',
  lighter: 'rgba(225, 229, 238, 1)',
  lighter01: 'rgba(225, 229, 238, 0.1)',
  lighter02: 'rgba(225, 229, 238, 0.2)',
  lighter03: 'rgba(225, 229, 238, 0.3)',
  lighter04: 'rgba(225, 229, 238, 0.4)',
  lighter05: 'rgba(225, 229, 238, 0.5)',
  lighter06: 'rgba(225, 229, 238, 0.6)',
  lighter07: 'rgba(225, 229, 238, 0.7)',
  lighter08: 'rgba(225, 229, 238, 0.8)',
  lighter09: 'rgba(225, 229, 238, 0.9)',
};
